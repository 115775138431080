/** * 合作案例 */
<template>
  <v-app class="section-box">
    <v-banner single-line height="300" class="banner">
      <v-img
        src="https://h5.ophyer.cn/official_website/banner/bannerWmyCase400.jpg"
        height="100%"
        :transition="false"
        :class="imgLoad ? 'v-image__image--animation' : ''"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-banner>
    <v-tabs
      centered
      fixed-tabs
      hide-slider
      class="new-tabs-two"
      color="rgb(245, 249, 253)"
      v-model="tab"
    >
      <v-tab
        :value="v.id"
        v-for="(v, k) in typeMenu"
        :key="k"
        @click="clickLevelTwo(k)"
        >{{ v.typeName }}</v-tab
      >
    </v-tabs>
    <div class="div-tabs">
      <v-tabs-items v-model="tab" class="new-tabs-items-two" v-cloak>
        <v-tab-item
          v-model="tab"
          v-for="(v, k) in list"
          :key="k"
        >
          <v-container fluid>
            <v-card
              class="mx-auto item-card"
              max-width="280"
              max-height="351"
              @click="goCaseDetail(v)"
            >
              <v-img
                max-height="260"
                class="tran-sec"
                :src="v.picUrl"
                :aspect-ratio="9 / 16"
              >
              </v-img>
              <div class="shareBox" @mouseover='isshow=true' @mouseleave='isshow=false'>
                 <div class="share">
                    <img src='../../../public/img/icons/wmyHome/share.png' width="12px" />
                 </div>
                 <section class="img_box" style="text-align: center" v-if='isshow'>
                   <span>
                     <vue-qr
                       :text="v.fileUrl"
                       :size="150"
                       :margin="10"
                       :correctLevel="1"
                       background-color="#f5f7fa"
                       class="temp-code"
                     ></vue-qr>
                   </span>
                 </section>
              </div>
              <div class="case-box">
                <div
                  style="
                    font-size: 14px;
                    font-weight: 500;
                    color: #333333;
                    line-height: 20px;
                  "
                  class="mt-4"
                >
                  {{ v.vrName }}
                </div>
                <div
                  style="
                    font-size: 12px;
                    font-weight: 400;
                    color: #999999;
                    line-height: 17px;
                  "
                  class="mt-2"
                >
                  {{ v.authorName }}
                  <span style="float: right">
                    <i class="el-icon-view">{{ v.openCount }}</i>
                    <img
                      src="../../../public/img/icons/wmyHome/dianzhan.png"
                      width="12px"
                      class="ml-2"
                    />{{ v.likesCount }}
                  </span>
                </div>
              </div>
            </v-card>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </div>

    <section class="page_box">
        <el-pagination
          @current-change="on_page"
          :current-page.sync="pageNum"
          :page-size="pageSize"
          layout="total, prev, pager, next"
          :total="pageTotal"
        >
        </el-pagination>
    </section>
  </v-app>
</template>

<script>
import qs from "qs";
import VueQr from "vue-qr";
const DEFAULT_FORM = {
  type: 0,
  typeId: "",
  vrName: "",
};
export default {
  name: "Case",
  components: { VueQr },
  data() {
    return {
      tab: null,
      imgLoad: false,
      form: Object.assign({}, DEFAULT_FORM),
      typeMenu:[], //分类列表
      list:[], //案例列表
      isshow:false, //是否展示二维码
      pageTotal: 0,
      pageNum: 1,
      pageSize: 20,
    };
  },
  mounted() {
    this.get_list()
    this.getTypeMenu()
  },
  methods: {
    on_page(val) {
      this.pageNum = val;
      this.get_list();
    },
    //分类下拉菜单
    getTypeMenu(){    
        let url =process.env.VUE_APP_NODE_ENV === "development"
          ? process.env.VUE_APP_API_DEV +'/vrview/api/caseType/getList'
          : process.env.VUE_APP_NODE_ENV === "test"
          ? "https://test.iwmy.igame123.com/apiProxy/vrview/api/caseType/getList"
          : "https://i.wmy.ophyer.com/apiProxy/vrview/api/caseType/getList";
        this.$axios.get(url).then((res) => {
           
           this.typeMenu = res.data.data;
           let data = {
             id:'',
             typeName:'全部'
           }
           this.typeMenu.unshift(data)
        });
    },
    get_list() {
      let url =
        process.env.VUE_APP_NODE_ENV === "development"
          ? process.env.VUE_APP_API_DEV +'/vrview/api/boutiqueCase/officalpage'
          : process.env.VUE_APP_NODE_ENV === "test"
          ? "https://test.iwmy.igame123.com/apiProxy/vrview/api/boutiqueCase/officalpage"
          : "https://i.wmy.ophyer.com/apiProxy/vrview/api/boutiqueCase/officalpage";
      console.log(url, "url");
      let param = this.form
      param.pageNum = this.pageNum
      param.pageSize = this.pageSize
      //let url = process.env.VUE_APP_API_DEV +'/vrview/api/boutiqueCase/officalpage'
      this.$axios.get(url, { params: param }).then((res) => {
        this.list = res.data.data.records;
        this.pageTotal = res.data.data.total
      });
    },
    goCaseDetail(item) {
      if (item.detail.type == 3) {
        window.open(item.detail.openUrl, "_blank");
      } else {
        let query = {
          source: "qita",
          anli: item.self,
        };
        let url = "/CaseDetail?" + qs.stringify(query);
        window.open(url, "_blank");
      }
    },

    clickLevelTwo(k) {
      this.form.typeId = k
      this.pageNum = 1
      this.get_list()
    },
  },
};
</script>

<style lang="scss" scoped>
.section-box {
  background: #f5f8fd;
  position: relative;
  z-index: 6;
}
.banner {
  position: relative;
  ::v-deep .v-banner__wrapper,
  ::v-deep .v-banner__content,
  ::v-deep .v-banner__text {
    padding: 0 !important;
    height: 100%;
  }
  .banner-text {
    text-align: center;
    margin-top: 20px;
  }
}

.g-tabs {
  height: 65px;
  background: #fff;
  padding-top: 10px;
  flex: unset;
  ::v-deep .v-tab {
    font-size: 22px;
    padding: 0 25px;
    &::before {
      background: unset !important;
    }
  }
  ::v-deep.v-ripple__container {
    display: none !important;
  }
}
.new-tabs-two {
  flex: unset;
}
::v-deep .new-tabs-items-two .v-window-item {
  margin: 15px 20px 0 !important;
  height: 358px;
}
::v-deep .new-tabs-items-two .v-window__container {
  padding-top: 15px !important;
}
::v-deep .new-tabs-items-two .item-card::after {
  bottom: 34px !important;
}

.div-tabs {
  min-height: 520px !important;
  position: relative;
  z-index: 7;
}
.shareBox{
  position: relative;
  .share{
    width: 20px;
    height: 20px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    text-align: center;
    padding-top: 3px;
    /* top: -300px; */
    position: absolute;
    top: -254px;
    left: 193px;
  }
  .img_box{
    position: absolute;
    top: -196px;
    left: 36px;
  }
}
</style>